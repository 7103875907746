import { useBreakpoints } from '@vueuse/core'

const breakpoints = () => {
  return useBreakpoints({
    small: 768,
    medium: 1024,
    large: 1280,
    xlarge: 1600,
    hd: 1920,
    '4k': 3840
  })
}

export { breakpoints }

export default breakpoints
