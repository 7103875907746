<template>
  <div class="p-events">
    <swiper
      :speed="1000"
      :modules="modules"
      :slides-per-view="1"
      :slides-per-group="1"
      ref="swiperContainer"
      class="swiper"
      @swiper="onSwiper"
      effect="fade"
      :autoplay="{
        delay: paginationDelay
      }"
      @activeIndexChange="handleIndexChange"
    >
      <swiper-slide v-for="group, idx in computedSlides" :key="idx">
          <a-event a-event :event="event"  v-for="event in group" :key="event.id"/>
      </swiper-slide>

    </swiper>
      <div v-if="currentPage && computedSlides.length" class="pagination">{{ currentPage }}/{{ computedSlides.length }}</div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { breakpoints } from '@/lib/helpers/breakpoints'

import 'swiper/css'

import 'swiper/css/pagination'
import 'swiper/css/effect-fade'

import { Autoplay, EffectFade, Pagination } from 'swiper'

import { ref, watch, computed } from 'vue'
import { useMediaQuery } from '@vueuse/core'
import { useQuery } from 'villus'
import { chunk } from 'lodash'

export default {
  components: {
    Swiper,
    SwiperSlide
  },

  setup () {
    const bps = breakpoints()
    const isSmall = bps.smaller('small')
    const isMedium = bps.between('small', 'medium')
    const isLarge = bps.between('medium', 'hd')

    const swiperInstance = ref(null)
    const onSwiper = (swiper) => {
      swiperInstance.value = swiper
    }

    const numSlidesPerView = computed(() => {
      let cardHeight = 115
      if (isSmall.value) { cardHeight = 305 }
      if (isMedium.value) { cardHeight = 263 }
      if (isLarge.value) { cardHeight = 115 }
      if (fullHdHorizontal.value) { cardHeight = 131 }
      if (fullHdHorizontalSmall.value) { cardHeight = 131 }
      if (fullHdVertical.value) { cardHeight = 232 }
      if (fourKHorizontal.value) { cardHeight = 266 }
      if (fourKVertical.value) { cardHeight = 307 }
      return cardHeight
    })

    const swiperContainer = ref(null)
    const handleIndexChange = (e) => {
      currentPage.value = e.realIndex + 1
    }

    const paginationDelay = 6000
    const events = ref([])
    const timer = ref(0)
    const timerInterval = ref(0)
    const timerDelay = 30000
    const currentPage = ref(1)
    const computedSlides = ref([])
    const containerHeight = computed(() => swiperContainer.value.$el.clientHeight)
    const dynamicString = ref(new Date())

    const fullHdHorizontal = useMediaQuery('(min-width: 1920px) and (min-height: 1080px)')
    const fullHdHorizontalSmall = useMediaQuery('(min-width: 1280px) and (min-height: 768px)')
    const fullHdVertical = useMediaQuery('(min-width: 1080px) and (min-height: 1920px)')
    const fourKHorizontal = useMediaQuery('(min-width: 3840px) and (min-height: 2160px)')
    const fourKVertical = useMediaQuery('(min-width: 2160px) and (min-height: 3840px)')

    const startTimer = () => {
      timerInterval.value = setInterval(() => {
        timer.value += timerDelay
      }, timerDelay)
    }

    const GetEventsQuery = `
      query getEventForRoomMonitoring ($ts: String) {
        roomMonitoring (ts: $ts) {
          id
          title
          location
          locationTerm {
            id
            name
            building
            floorRaw
            floor
          }
          date
          noEndDateTabellio
          eventType {
            ... on EventType {
              id
              closedDoors
            }
          }
        }
      }
    `

    const variables = computed(() => {
      return {
        ts: dynamicString.value
      }
    })

    const { data, execute } = useQuery({
      query: GetEventsQuery,
      variables
    })

    watch(data, () => {
      if (data?.value?.roomMonitoring) {
        events.value = data.value.roomMonitoring.map(e => ({
          id: e?.id,
          hour: computeHour({ start: e.date?.[0], end: e?.noEndDateTabellio ? null : e.date?.[1] }),
          title: e?.title,
          room: e?.locationTerm?.[0]?.name,
          roomLegend: computeRoom(e?.locationTerm?.[0]?.building, e?.locationTerm?.[0]?.floor)
        }))
      }
      // for (let i = 0; i < 10; i++) {
      //   events.value.push({
      //     id: i,
      //     hour: computeHour({ start: new Date(), end: new Date() }),
      //     title: `Slide Groupe PS: reunion de travail lorem ipsum dolores arman dolika commission separation, de la famille, de la culture wallone et des communes, lorem ipsum dolores et lorem ipsum dolores ${i}`,
      //     room: 'Salle Philippe Geluck',
      //     roomLegend: 'HdL - Rez-de-chaussée'
      //   })
      // }
      computedSlides.value = chunk(events.value, containerHeight.value / numSlidesPerView.value)
    })

    // NOTE Formating
    const computeRoom = (building, floor) => {
      const arr = []
      if (building) { arr.push(building) }
      if (floor) { arr.push(floor) }
      const string = arr?.length ? `(${arr.join(' - ')})` : arr.join(' - ')

      return string
    }

    const validHour = (string) => {
      return string && new Date(string).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
    }

    const computeHour = ({ start, end }) => {
      const arr = []
      if (start) { arr.push(validHour(start)) }
      if (end) { arr.push(validHour(end)) }
      return arr.join(' à ')
    }

    // NOTE Controls
    const startLoop = async () => {
      execute()
      startTimer()
    }

    // NOTE Init
    startLoop()

    // NOTE Watchers & computed
    watch([currentPage, timer], () => {
      if (currentPage.value === 1 && timer.value >= timerDelay) {
        dynamicString.value = new Date()
        execute()
        timer.value = 0
      }
    })

    return {
      currentPage,
      computedSlides,
      modules: [Pagination, EffectFade, Autoplay],
      events,
      onSwiper,
      swiperContainer,
      paginationDelay,
      swiperInstance,
      handleIndexChange,
      numSlidesPerView,
      containerHeight
    }
  }
}

</script>

<style lang="scss" scoped>
.p-events {
  height: 100%;
}

.swiper {
  overflow: visible;
  height: 90%;
  max-width: 100%;
}

.pagination {
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: font-sizes('small');

  // FullHD Vertical
  @media screen and (min-width: 1080px) and (min-height: 1920px) {
    font-size: font-sizes('base');
  }

  // FullHD Horizontal
  @media screen and (min-width: 1920px) and (min-height: 1080px) {
    font-size: font-sizes('small');
  }

  // 4K Vertical
  @media screen and (min-width: 2160px) and (min-height: 3840px) {
    font-size: font-sizes('xlarge');
    letter-spacing: 0.025rem;
  }

  // 4K Horizontal
  @media screen and (min-width: 3840px) and (min-height: 2160px) {
    font-size: font-sizes('large');
    letter-spacing: 0.025rem;
  }
}

.swiper-slide {
  display: grid;
  grid-auto-rows: min-content;
  gap: 3rem;

  // 4K Horizontal
  @media screen and (min-width: 3840px) and (min-height: 2160px) {
    gap: 4rem;
  }

  // 4K Vertical
  @media screen and (min-width: 2160px) and (min-height: 3840px) {
    gap: 6rem;
  }
}

.swiper-slide,
.swiper-slide-next,
.swiper-slide-prev {
  opacity: 0 !important;
}

.swiper-slide-active {
  opacity: 1 !important;
}
</style>
