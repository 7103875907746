import { createApp } from 'vue'
import VueSnip from 'vue-snip'

import App from './App.vue'
import AEvent from './components/AEvent.vue'
import PEvents from './components/PEvents.vue'

const app = createApp(App)

app.component('AEvent', AEvent)
app.component('PEvents', PEvents)
app.use(VueSnip)

app.mount('#app')
