<template>
  <div class="container">
    <div class="app-inner">
      <header class="header">
        <img class="header__logo" src="@/assets/images/logo.svg" alt="Logo PWFB">
      </header>

      <main class="main">
        <p-events class="events"/>
      </main>

      <footer class="footer">
        <div>HdL = Hotel de Ligne</div>
        <div>HdG = Hotel du Greffe</div>
      </footer>
    </div>
  </div>
</template>

<script>
import '@/assets/base.css'
import { useClient } from 'villus'

export default {
  setup () {
    useClient({
      url: `${process.env.VUE_APP_BE}/graphql`,
      cachePolicy: 'network-only'
    })
  }
}

</script>

<style lang="scss" scoped>
.container {
  max-width: 3460px;
  padding: 0 100px;
  margin: 0 auto;
  height: 100vh;

  @include media('small-medium') {
    max-width: 1540px;
    padding: 0 20px;
  }

  // only FullHD Horizontal
  @media screen and (min-width: 1920px) and (min-height: 1080px) and (max-height: 2159px) {
    max-width: 1540px;
  }

  // 4K Vertical
  @media screen and (min-width: 2160px) and (min-height: 3840px) {
    max-width: 1780px;
  }
}

.main {
  position: relative;
  min-width: 100%;
  max-width: 100%;

  .events {
    overflow: visible;
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
}

.app-inner {
  display: grid;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  grid-template-rows: 145px 1fr 120px;

  .header {
    padding-top: 6rem;
    padding-bottom: 4rem;

    &__logo {
      height: 100%;
    }
  }

  // FullHD Vertical
  @media screen and (min-width: 1080px) and (min-height: 1920px) {
    grid-template-rows: 200px 1fr 120px;

    .header {
      padding-top: 6rem;
      padding-bottom: 7rem;
    }
  }

  // 4K Vertical
  @media screen and (min-width: 2160px) and (min-height: 3840px) {
    grid-template-rows: 300px 1fr 260px;

    .header {
      padding-top: 6rem;
      padding-bottom: 10rem;
    }
  }

  // 4K Horizontal
  @media screen and (min-width: 3840px) and (min-height: 2160px) {
    grid-template-rows: 300px 1fr 260px;

    .header {
      padding-top: 10rem;
      padding-bottom: 6rem;
    }
  }
}

.footer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6rem;
  line-height: 1.33;
  font-size: font-sizes('small');

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: #ccc;
  }

  // FullHD Vertical
  @media screen and (min-width: 1080px) and (min-height: 1920px) {
    font-size: font-sizes('base');
  }

  // FullHD Horizontal
  @media screen and (min-width: 1920px) and (min-height: 1080px) {
    font-size: font-sizes('small');
  }

  // 4K Vertical
  @media screen and (min-width: 2160px) and (min-height: 3840px) {
    font-size: font-sizes('xlarge');
    letter-spacing: 0.025rem;
  }

  // 4K Horizontal
  @media screen and (min-width: 3840px) and (min-height: 2160px) {
    font-size: font-sizes('large');
    letter-spacing: 0.025rem;
  }
}
</style>
